/**
 * 获取视频第一帧作为回显封面
 */
export function getVideoCover(file, _self) {
  const video = document.createElement('video')
  video.src = file.url
  let canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  video.crossOrigin = 'anonymous'
  video.currentTime = 1

  video.oncanplay = () => {
    canvas.width = video.clientWidth ? video.clientWidth : 320
    canvas.height = video.clientHeight ? video.clientHeight : 320
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
    let _videoFirstimgsrc = canvas.toDataURL('image/png')
    _self.form.coverImg = _videoFirstimgsrc
    _self.$set(file, 'url', _videoFirstimgsrc)
    video.remove()
    canvas.remove()
  }
}

/**
 * 随机生成文件名
 */
export function randomString(len) {
  len = len || 32
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678',
    maxPos = chars.length,
    pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

export function isAssetTypeAnImage(fileName) {
  const ext = fileName.substring(fileName.lastIndexOf('.') + 1)
  return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1
}
