import { request } from '@/network/request'

/**
 *
 * @returns 根据Token获取用户信息
 */
export function getUserInfoByToken(token) {
  return request({
    method: 'get',
    url: '/user/token_login',
    params: {
      token: token
    }
  })
}

/*获取我的搜索记录/热门关键词*/
export function getSearchKeyword() {
  return request({
    url: '/user/get_search_keyword'
  })
}

/*查询类型*/
export function getType() {
  return request({
    url: '/trade/trade_level'
  })
}

/*查询所有*/
export function getHomeListItem() {
  return request({
    url: '/trade/trade_class', //url:'/trade/trade_class?token='+localStorage.getItem('token'),
    method: 'get'
  })
}

/*根据查询信息*/
export function getTradeList(cat_id1, p, keyword) {
  return request({
    url: '/trade/index',
    params: {
      cat_id1,
      p,
      keyword
    }
  })
}
/*根据关键字搜索信息*/
export function getHomeListByKey(keyword) {
  return request({
    url: '/trade/index',
    params: {
      keyword
    }
  })
}

/*根据分类查询*/
export function getHomeTypeList(cat_id1) {
  return request({
    url: '/trade/index',
    params: {
      cat_id1
    }
  })
}
/*根据企业ID查询*/
export function getTradeByStore(store_id, p) {
  return request({
    url: '/trade/index',
    params: { p, store_id }
  })
}

/*根据用户id查询*/
export function getUserGuideList(id) {
  return request({
    url: '/guide/finduseridGuide',
    params: {
      id
    }
  })
}

/*根据城市id查询*/
export function getCityGuideList(id) {
  return request({
    url: '/guide/findcityidGuide',
    params: {
      id
    }
  })
}

/*------------------------------------------*/
/*详情*/
export function getSupplyDetail(id) {
  return request({
    url: '/trade/supply_detail',
    params: {
      id
    }
  })
}

/*评论列表*/
export function getCommentList(id) {
  return request({
    url: '/trade/comment_list',
    params: {
      id
    }
  })
}

/*添加评论*/
export function addComment(id, c_id, comment_info, userId) {
  return request({
    url: '/trade/add_comment',
    params: {
      id,
      c_id,
      comment_info,
      userId
    }
  })
}

/*获取回复评论
 * */
export function getDetailReply(id) {
  return request({
    url: '/replycom/findreply',
    params: {
      id
    }
  })
}

/*添加评论*/
export function addReplycom(comment_id, reply, user_id) {
  return request({
    url: '/replycom/addreply',
    params: {
      comment_id,
      reply,
      user_id
    }
  })
}

/*修改点赞数量*/
export function updatefabs(id, numfabs) {
  return request({
    url: '/guide/updatefabs',
    params: {
      id,
      numfabs
    }
  })
}

/*修改收藏数量*/
export function updatecollection(id, numcollection) {
  return request({
    url: '/guide/updatecollection',
    params: {
      id,
      numcollection
    }
  })
}

/*图片上传*/
export function Fileup() {
  return request({
    method: 'POST',
    url: '/guide/filep'
  })
}

/*添加内容*/
export function addHome(content, title, typeId, userId, city_id) {
  return request({
    url: '/guide/addhome',
    params: {
      content,
      title,
      typeId,
      userId,
      city_id
    }
  })
}

/**
 * 发送手机验证码
 * @param {手机号} mobile
 * @returns
 */
export function sendSmsCode(mobile) {
  return request({
    url: '/index/send_validate_code',
    params: {
      type: 'mobile',
      scene: '6',
      mobile
    }
  })
}
