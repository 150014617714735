<template>
  <div style="">
    <!--头部导航-->
    <nav-bar class="home-nav-bar" style="z-index: 9;">
      <div slot="left">
        <!-- <i class="fas fa-angle-left"></i> -->
        <img src="@/assets/img/logo.png" style="width:60px;" />
      </div>
      <div slot="center" class="slot-center" id="nav-ul-1">
        <div class="">
          <el-input
            size="mini"
            placeholder=""
            prefix-icon="el-icon-search"
            v-model="input2"
            @focus="btngosearch"
          >
          </el-input>
        </div>
      </div>
      <!-- <div slot="center" class="slot-center" id="nav-ul-1">
                <div class="nav-center" v-for="(item,index) in center" :key="item.id"
                     :class="{active1:currIndex1===index}"
                     @click="btnclick(index)">
                    <span >{{item}}</span>
                </div>
                <li id="nav-li-overlay-1"></li>
            </div>  -->
      <!-- <div slot="right" class="fas fa-search" @click="btngosearch"></div> -->
    </nav-bar>

    <!--搜索-->
    <home-search @btnout="btnout" class="home-search" id="home-search" ref="homeSearch"></home-search>

    <!--推荐导航中部内容-->
    <router-view></router-view>

    <!--底部撑高-->
    <div class="home-bottom"></div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import HomeSearch from './search/HomeSearch'

export default {
  name: 'Home',
  created() {},
  data() {
    return {
      center: ['关注', '发现', '附近'],
      routers: ['/followlist', '/homelist', '/nearbylist'],
      currIndex1: 1,
      input2: ''
    }
  },
  components: {
    NavBar,
    HomeSearch
  },
  methods: {
    btnclick(index) {
      this.currIndex1 = index
      var leftx = document.getElementById('nav-li-overlay-1')
      var parentleft = document.getElementById('nav-ul-1')
      leftx.style.left = this.currIndex1 * (parentleft.offsetWidth / 3) + 'px'
      //跳转
      this.$router.push(this.routers[index])
    },

    btngosearch() {
      let homesearch = document.getElementById('home-search')
      homesearch.style.left = 0 + 'vh'
      this.$refs.homeSearch.showKey()
    },
    btnout() {
      let homesearch = document.getElementById('home-search')
      homesearch.style.left = 100 + 'vh'
    }
  }
}
</script>

<style scoped>
.home-nav-bar {
  position: sticky;
  top: 0.01px;
  background-color: #fff;
  text-align: center;
}
/*头部导航*/

.slot-center {
  font-size: 14px;
  color: rgb(150, 150, 150);
  position: relative;
  display: flex;
}
.slot-center div {
  flex: 1;
}
.slot-center span {
  margin: 0 8px;
}

#nav-li-overlay-1 {
  position: absolute;
  left: 33.3%;
  top: 83%;
  z-index: -1;
  height: 1px;
  width: 33.3%;
  /*background-color: #e4f2ff;*/
  background-color: red;
  transition: 0.4s;
  border-radius: 5px;
}

.active1 {
  color: #000;
}

.home-bottom {
  width: 100%;
  height: 49px;
  border-radius: 20px 20px 0 0;
}

/*搜索*/
.home-search {
  position: fixed;
  height: 100vh;
  width: 100vh;
  background-color: #fff;
  z-index: 10;
  left: 100vh;
  right: 0;
  bottom: 0;
  transition: left 0.6s;
}

.el-input /deep/ input.el-input__inner {
  /* border: none; */
  height: 32px;
  border-radius: 15px;
  border-color: #f4f4f4;
  box-shadow: 2px 2px 2px #eeeeee;
}
</style>
