<template>
  <!-- 这个是内容 -->
  <div class="home-list-item" style="overflow-x:hidden;">
    <waterfall :col="2" :data="this.homelist" style="margin-left: 0px" @loadmore="loadmore">
      <div
        class="list-item"
        style="width:96%"
        v-for="index in homelist.length"
        :key="index"
        @click="btndetail(homelist[index - 1].id)"
      >
        <!-- <v-touch v-on:swipeleft="swiperleft" v-on:swiperight="swiperright" class="wrapper">
            <div class="menu-container" ref="menuContainer">     -->
        <div class="item-image">
          <img v-if="homelist[index - 1].type == 'video'" v-lazy="homelist[index - 1].coverImg" alt="加载错误" />
          <img v-else v-lazy="homelist[index - 1].img[0]" alt="加载错误" /><!-- 推荐图片/视频 -->
        </div>
        <div class="item-body">
          <div class="item-title">{{ homelist[index - 1].title }}</div>
          <div class="item-list-user">
            <img
              :src="homelist[index - 1].fbr ? homelist[index - 1].fbr.img : homelist[index - 1].head_pic"
              class="item-list-user-img"
            /><!-- 用户头像 -->
            <span class="item-name">{{
              homelist[index - 1].fbr ? homelist[index - 1].fbr.name : homelist[index - 1].nickname
            }}</span
            ><!-- 用户昵称 -->

            <div class="item-fabs">
              <span class="iconfont icon-heart heart"></span>
              <span style="margin-top:2px">{{ homelist[index - 1].zan }}</span
              ><!-- 点赞次数 -->
            </div>
          </div>
        </div>
        <!-- </div>
        </v-touch> -->
      </div>
    </waterfall>

    <!-- <v-touch v-on:swipeleft="swiperleft" v-on:swiperight="swiperright" class="wrapper" style="position:fixed;width: 400px;height: 60vh;z-index:0;background-color:rgba(255, 255, 0, 0.5);">
      <div class="menu-container" ref="menuContainer">  
      </div>
    </v-touch>  -->
  </div>
</template>
<script>
import { isAssetTypeAnImage } from '@/network/common'
export default {
  name: 'HomeListItem',
  props: {
    homelist: {
      type: Array,
      default() {
        return []
      }
    },
    typeIndex: {
      type: [String, Number]
    },
    originPage: {
      type: [String, String]
    }
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_IMG_PATH,
      user_id: '',
      fabslist: [],
      x: 0
    }
  },
  watch: {
    homelist(newValue) {
      if (newValue) {
        newValue.forEach((item) => {
          if (isAssetTypeAnImage(item.img[0])) {
            item.type = 'img'
          } else {
            item.type = 'video'
          }
        })
      }
      this.homelist = newValue
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
  },
  mounted() {},
  methods: {
    swiperleft: function() {
      if (this.originPage == 'HomeList') {
        console.log('左划')
        this.$store.commit('initTradeListPageIndex')
        if (this.typeIndex + 1 < this.$store.state.typeListLenght) {
          this.$store.commit('setTypeIndex', this.typeIndex + 1)
          this.$store.commit('initTradeListPageIndex')
          this.$parent.queryTradeList(this.typeIndex + 1)
        }
      }
    },
    swiperright: function() {
      if (this.originPage == 'HomeList') {
        console.log('右滑')
        this.$store.commit('initTradeListPageIndex')
        if (this.typeIndex > 0) {
          this.$store.commit('setTypeIndex', this.typeIndex - 1)
          this.$store.commit('initTradeListPageIndex')
          this.$parent.queryTradeList(this.typeIndex - 1)
        }
      }
    },
    btndetail(cid) {
      //增加首页缓存
      this.$store.state.homelistKeepAlive=true;
      this.$router.push('/homedetail/' + cid)
    },
    loadmore() {
      this.$store.commit('addTradeListPageIndex')
      this.$parent.queryTradeList(this.typeIndex)
    }
  }
}
</script>

<style scoped>
.home-list-item {
  margin-left: 1px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgba(230,230,230,.8); */
}

.list-item {
  width: 47.3%;
  margin: 4px 5px;
  background-color: #fff;
  border-radius: 8px;
}
.item-image img,
.item-image video {
  width: 100%;
  border-radius: 8px;
}
.item-title {
  width: 100%;
  padding: 5px 0;
  margin: 0 5px;
  font-size: 15px;
}
.item-list-user img {
  width: 20px;
  border-radius: 50%;
}

.item-list-user {
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.item-name {
  font-size: 8px;
  color: rgb(120, 120, 120);
  flex: 1;
  margin-left: 6px;
}
.item-fabs {
  font-size: 12px;
  color: rgb(120, 120, 120);
  display: flex;
}
.item-fabs .icon-heart {
  margin-right: 5px;
}
.heart {
  color: rgb(120, 120, 120);
}
.headred {
  background-color: red;
}
</style>
