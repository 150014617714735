<template>
  <nav class="nav-bar">
    <div class="left"><slot name="left"></slot></div>
    <div class="center"><slot name="center"></slot></div>
    <div class="right"><slot name="right"></slot></div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  props: {}
}
</script>

<style scoped>
.nav-bar {
  display: flex;
  flex-direction: row;
  height: 48px;
  line-height: 38px;
  /* text-align: center; */
  /*阴影*/
  /* box-shadow: 0 3px 6px rgba(100,100,100,.3); */
  /* border-bottom: 1px solid rgba(150,150,150,.3);  */
}
.left {
  width: 20%;
}
.center {
  width: 75%;
  /* flex: 1; */
}
.right {
  width: 0%;
}
</style>
