import axios from 'axios'
import router from '../router'

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
axios.defaults.headers['Content-Language'] = 'zh_CN'
export function request(config) {
  let basePath = process.env.VUE_APP_BASE_API + '/api'
  if (config && config.url.indexOf('/tokenServer') >= 0) {
    basePath = '/'
  }
  const instance = axios.create({
    baseURL: basePath,
    timeout: 30000,
    params: {
      token: localStorage.getItem('token')
    },
    data: { token: localStorage.getItem('token') }
  })

  instance.interceptors.response.use((response) => {
    if (response) {
      const status = response.data.status || response.status
      switch (status) {
        case -100:
        case -101:
          router.replace({
            //跳转到登录页面
            path: '/login',
            query: { redirect: router.currentRoute.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
          })
      }
    }
    return response
  })
  //发送真正的网络请求
  return instance(config)
}
