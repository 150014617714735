<template>
  <div class="search">
    <!--顶部导航栏-->
    <div class="search-nav">
      <div @click="btnout" class="search-out">
        <i class="fas fa-angle-left"></i>
      </div>
      <div :style="{ width: $store.state.screenWidth - 90 + 'px' }">
        <el-input size="mini" placeholder="" prefix-icon="el-icon-search" v-model="title"> </el-input>
      </div>

      <div class="search-btn" @click="btnsearch">
        <span>搜索</span>
      </div>
    </div>
    <div id="search-key">
      <div class="historykey" style="padding-top:10px" id="history-recomment">
        <div>
          <div class="search-recomment-head">
            <span>历史记录</span>
          </div>
          <div class="search-history">
            <div
              class="search-history-item"
              v-for="item in search_keyword_user"
              :key="item.id"
              @click="btnsearchcomment(item.name)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="search-recomment" id="search-recomment">
                <div class="search-recomment-head">
                    <span>历史记录</span>
                </div>
                <div class="search-recomment-conten">
                    <div v-for="item in historySearchRecomment" :key="item.id">
                        <span @click="btnsearchcomment(item)">{{item}}</span>
                    </div>
                </div>
            </div>  -->

      <div class="search-recomment" id="hot-recomment" style="padding-top:10px">
        <div class="search-recomment-head">
          <span>热门搜索</span>
        </div>
        <div class="search-recomment-conten">
          <div v-for="item in hotSearchRecomment" :key="item.id">
            <span @click="btnsearchcomment(item.name)">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="historykey">
            <div v-show="searchHistory">
                <div class="his">历史记录</div>
                <div class="search-history">
                    <div class="search-history-item" v-for="item in historySearchRecomment" :key="item.id" @click="btnsearchcomment(item)">{{item}}</div>
                </div>
            </div>
            
            <div class="hot">热门搜索</div>
            <div class="hot-content">
                <ul>
                    <li v-for="item in hotSearchRecomment" :key="item.id">
                        {{item}}
                    </li>
                </ul>
            </div>
        </div> -->

    <!--内容-->
    <div class="search-content">
      <div v-if="listItemShow && homesearchlist.length == 0" style="margin:20px 0 0 20px;font-size: 14px">
        没有搜索到结果
      </div>
      <home-list-item
        v-if="listItemShow && homesearchlist.length > 0"
        class="home-search-item"
        :homelist="homesearchlist"
      ></home-list-item>
    </div>
  </div>
</template>

<script>
import HomeListItem from '../homechild/HomeListItem'
import { getHomeListByKey, getTradeList, getSearchKeyword } from 'network/home'

export default {
  name: 'HomeSearch',
  data() {
    return {
      title: '',
      listItemShow: false,
      search_keyword_user: [],
      hotSearchRecomment: [],
      homesearchlist: [],
      historySearchRecomment: []
    }
  },
  components: {
    HomeListItem
  },
  created() {
    // this.showKey();
    this.getSearchKeyword()
  },
  methods: {
    hiddenKey() {
      let searchRecomment = document.getElementById('search-key')
      searchRecomment.style.display = 'none'
      this.listItemShow = true
      // let hisSearchRecomment=document.getElementById("history-recomment");
      // let hotSearchRecomment=document.getElementById("hot-recomment");
      // hisSearchRecomment.style.display='none'
      // hotSearchRecomment.style.display='none'
    },
    showKey() {
      let searchRecomment = document.getElementById('search-key')
      searchRecomment.style.display = 'block'
      this.listItemShow = false
      // let hisSearchRecomment=document.getElementById("history-recomment");
      // let hotSearchRecomment=document.getElementById("hot-recomment");
      //     hisSearchRecomment.style.display='block'
      //     hotSearchRecomment.style.display='block'
    },
    btnout() {
      this.homesearchlist = []
      this.hiddenKey()
      this.$emit('btnout')
    },

    /*单击搜索*/
    btnsearch() {
      var that = this
      this.search_keyword_user = []
      this.hotSearchRecomment = []
      this.$store.commit('initTradeListPageIndex')
      getHomeListByKey(this.title).then((res) => {
        that.homesearchlist = res.data.result.trade_list
      })
      this.hiddenKey()
    },

    btnsearchcomment(item) {
      this.title = item
      getHomeListByKey(this.title).then((res) => {
        this.homesearchlist = res.data.result.trade_list
      })
      this.hiddenKey()
    },

    /*查询信息*/
    queryTradeList(typeIndex) {
      var pageIndex = this.$store.state.tradeListPageIndex
      getTradeList(typeIndex, pageIndex, this.title).then((res) => {
        if (pageIndex == 1) {
          this.homesearchlist = []
        }
        this.homesearchlist = this.homesearchlist.concat(res.data.result.trade_list)
      })
    },
    getSearchKeyword() {
      var that = this
      getSearchKeyword().then((res) => {
        that.search_keyword_user = res.data.result.search_keyword_user
        that.hotSearchRecomment = res.data.result.hot_keyword
      })
    }
  }
}
</script>

<style scoped>
/*导航栏*/
.search-nav {
  width: 100vh;
  height: 38px;
  background-color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
}
.search-out {
  width: 6%;
}
.search-out i {
  font-size: 20px;
}
.search-input {
  width: 42%;
}
.search-input input {
  border: 0;
  background-color: rgba(220, 220, 220, 0.6);
  /*光标颜色*/
  caret-color: #1bc4fb;
  /*选中后*/
  outline: 0;
  color: #000;
  /*高度*/
  padding: 6px;
  width: 90%;
  border-radius: 20px;
  font-size: 12px;
}
.search-btn span {
  font-size: 14px;
  color: rgb(120, 120, 120);
  margin-left: 5px;
}

.search-content {
  width: 100vh;
  height: 100vh;
  /*超出部分可滑动*/
  overflow-y: auto;
}
/*滚动条隐藏*/
.search-content::-webkit-scrollbar {
  display: none;
}
.home-search-item {
  width: 56%;
}

/*推荐搜索*/
.search-recomment {
  /* width: 100vh;
        height: 100vh; */
}
.search-recomment-head {
  padding: 10px 0 0 10px;
}
.search-recomment-head span {
  font-size: 15px;
  color: rgb(50, 50, 50);
}

.search-recomment-conten {
  width: 50%;
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
}
.search-recomment-conten div {
  width: 50%;
  margin: 7px 0;
}
.search-recomment-conten span {
  font-size: 12px;
  color: rgb(50, 50, 50);
}

.search {
  width: 100%;
  height: 60px;
  background: #fff;
}
.search-input {
  width: 100%;
  background: #f5f8fa;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-input img {
  height: 30px;
  width: 30px;
  display: inline-block;
  margin: 0 5px;
}
.search-input form,
.search-input input {
  width: 100%;
  height: 100%;
  border: none;
  background: #f5f8fa;
  font-size: medium;
  flex-grow: 1;
  border-radius: 5px;
  outline: none;
}
.search-cancel {
  height: 40px;
  width: 55px;
  margin: 10px auto;
  line-height: 40px;
  overflow: hidden;
  transition: width 0.3s;
  cursor: pointer;
}
.historykey {
  display: flex;
  flex-direction: column;
}
.search-history {
  margin: 0.46rem;
  width: 360px;
  flex-wrap: wrap;
}
.search-history-item {
  float: left;
  font-size: 0.7rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.37rem;
  padding-left: 0.43rem;
  padding-right: 0.43rem;
  border: 1px solid #eeeeee;
  border-radius: 15px;
}
.his {
  width: 10rem;
  height: 1.06rem;
  color: #a2a2a2;
  padding-top: 0.33rem;
  padding-left: 0.46rem;
  background-color: #f5f8fa;
}
.hot {
  width: 10rem;
  height: 1.06rem;
  color: #999999;
  padding-top: 0.33rem;
  padding-left: 0.46rem;
  margin-bottom: 0.46rem;
  background-color: #f5f8fa;
}
.hot-content ul {
  width: 100%;
  list-style: none;
  margin-left: 0.46rem;
}
.hot-content ul li {
  float: left;
  color: #a0a1a2;
  font-size: 0.46rem;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.37rem;
  padding-left: 0.43rem;
  padding-right: 0.43rem;
  background-color: #f5f8fa;
}

.el-input /deep/ input.el-input__inner {
  /* border: none; */
  height: 32px;
  border-radius: 15px;
  background-color: #f4f4f4;
}
</style>
